import type { PropType } from 'vue'
import {
  FlexRender,
  type Header,
  type HeaderGroup,
  type RowData,
} from '@tanstack/vue-table'
import type { VirtualItem } from '@tanstack/vue-virtual'
import { useTableStore } from './composables'

export default defineComponent({
  name: 'DataTableFooter',
  props: {
    groups: {
      type: Array as PropType<HeaderGroup<RowData>[]>,
      required: true,
    },
  },
  setup(props) {
    const { hasVirtualColumns, variants, getCommonStyles, displayHeader } =
      useTableStore()!

    return () => (
      <tfoot class={variants.tfoot()}>
        {props.groups.map((headerGroup) => {
          let headers = headerGroup.headers

          if (hasVirtualColumns.value) {
            headers = headers.filter(displayHeader)
          }

          return (
            <tr key={headerGroup.id} class={variants.tfootTr()}>
              {/**fake empty column to the left for virtualization scroll padding **/}
              {hasVirtualColumns.value ? (
                <th
                  class={variants.tfootTh()}
                  style={{
                    display: 'var(--virtual-padding-left-display)',
                    width: `calc(var(--virtual-padding-left) * 1px)`,
                  }}
                />
              ) : null}
              {headers.map((header) => (
                <th
                  key={header.id}
                  class={variants.tfootTh()}
                  colSpan={header.colSpan}
                  style={{
                    ...getCommonStyles(header.column, header),
                    width: `calc(var(--header-${header.id}-size) * 1px)`,
                  }}
                >
                  <div class={variants.tfootThInner()}>
                    <FlexRender
                      render={header.column.columnDef.footer}
                      props={header.getContext()}
                    />
                  </div>
                </th>
              ))}
              {/**fake empty column to the left for virtualization scroll padding **/}
              {hasVirtualColumns.value ? (
                <th
                  class={variants.theadTh()}
                  style={{
                    display: 'var(--virtual-padding-right-display)',
                    width: `calc(var(--virtual-padding-right) * 1px)`,
                  }}
                />
              ) : null}
            </tr>
          )
        })}
      </tfoot>
    )
  },
})
